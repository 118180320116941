<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="clearfix">
    <div class="mb-4">
      <p class="fsize12 font-medium mb-1">Name</p>
      <vs-input
        v-validate="'required|alpha_spaces|min:3|max:50'"
        maxlength="50"
        data-vv-validate-on="blur"
        name="displayName"
        placeholder="Name"
        v-model="displayName"
        class="w-full"
      />
      <span class="text-danger text-sm">{{ errors.first("displayName") }}</span>
    </div>
    <div class="mb-4">
      <p class="fsize12 font-medium mb-1">Mobile</p>
      <vs-input
        v-validate="'required|numeric|max:10|min:10'"
        maxlength="10"
        data-vv-validate-on="blur"
        name="mobile"
        type="tel"
        placeholder="Mobile"
        v-model="mobile"
        @blur="checkMobileNumber"
        class="w-full"
      />
      <span class="text-danger text-sm">{{ errors.first("mobile") }}</span>
    </div>
    <div class="mb-4">
      <p class="fsize12 font-medium mb-1">Email</p>
      <vs-input
        v-validate="'required|email'"
        maxlength="100"
        data-vv-validate-on="blur"
        placeholder="Email"
        name="email"
        v-model="email"
        class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('email')">{{
        errors.first("email")
      }}</span>
    </div>
    <div class="mb-4">
      <p class="fsize12 font-medium mb-1">LDAP ID</p>
      <vs-input
        v-validate="'required'"
        maxlength="50"
        data-vv-validate-on="blur"
        placeholder="LDAP ID"
        name="ldap"
        v-model="ldapid"
        class="w-full"
      />
      <span class="text-danger text-sm" v-show="errors.has('ldap')">{{
        errors.first("ldap")
      }}</span>
    </div>
    <div class="mb-4">
      <p class="fsize12 font-medium mb-1">LDAP Password</p>
      <vs-input
        ref="password"
        type="password"
        maxlength="100"
        data-vv-validate-on="blur"
        v-validate="'required|min:6|max:20'"
        name="password"
        placeholder="LDAP Password"
        v-model="password"
        class="w-full"
      />
      <span class="text-danger text-sm d-block">{{
        errors.first("password")
      }}</span>
    </div>

    <!-- <vs-input
      type="password"
      v-validate="'min:6|max:20|confirmed:password'"
      data-vv-validate-on="blur"
      data-vv-as="password"
      name="confirm_password"
      label-placeholder="Confirm Password"
      placeholder="Confirm Password"
      v-model="confirm_password"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{
      errors.first("confirm_password")
    }}</span> -->

    <div class="flex justify-between gap-4">
      <div class="w-1/2">
        <div class="mb-4">
          <p class="fsize12 font-medium mb-1">OTP</p>
          <vs-input
            type="password"
            maxlength="6"
            v-validate="'min:6|max:6'"
            name="opt"
            placeholder="OTP"
            v-model="otp"
            class="w-full"
          />
        </div>
      </div>
      <vs-button
        class="float-right my-6 p-3"
        v-if="!isOTPGenerated"
        @click="generateOTP"
        :disabled="!validateGenerateOTP"
        >Generate OTP</vs-button
      >
      <vs-button
        class="float-right my-6"
        v-if="isOTPGenerated"
        @click="generateOTP"
        :disabled="!validateGenerateOTP"
        >Regenerate OTP</vs-button
      >
    </div>
    <div class="flex">
      <vs-checkbox
        v-model="isTermsConditionAccepted"
        class="mt-6"
      ></vs-checkbox>
      <p class="mt-6">
        I accept the
        <a class="cursor-pointer" @click.prevent="openTerms"
          >terms & conditions</a
        >.
      </p>
    </div>
    <vs-button type="border" to="/pages/customadminlogin" class="mt-6"
      >Go to login</vs-button
    >
    <vs-button
      class="float-right mt-6"
      @click="checkOTP"
      :disabled="!validateForm"
      >Register</vs-button
    >
  </div>
</template>

<script>
import jwt from "jsonwebtoken";
import Datepicker from "vuejs-datepicker";
//import axios from 'axios';
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const userRepository = RepositoryFactory.get("user");
const notificationRepository = RepositoryFactory.get("notifications");
import { ClientRepositoryFactory } from "@/Client/Kotak/kotakapicall/ClientRepositoryFactory";
const LDAPRepository = ClientRepositoryFactory.get("LDAP");
import reCaptchaMixin from "@/Client/Kotak/mixins/reCaptchaMixin";
import validationMixin from "@/Core/mixins/validationMixin";

export default {
  mixins: [reCaptchaMixin, validationMixin],
  data() {
    return {
      displayName: "",
      email: "",
      password: "",
      mobile: "",
      gender: null,
      dob: null,
      confirm_password: "",
      isTermsConditionAccepted: false,
      genderValidationMessage: "",
      otp: "",
      verified: false,
      generatedOTP: 0,
      isInvited: false,
      communityManagers: [],
      isOTPGenerated: false,
      ldapid: "",
    };
  },
  watch: {
    displayName(val) {
      this.scriptValidation(val, "displayName");
    },
    mobile(val) {
      this.scriptValidation(val, "mobile");
    },
    email(val) {
      this.scriptValidation(val, "email");
    },
    ldapid(val) {
      this.specialCharacterValidation(val, "ldapid");
    },
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.displayName != "" &&
        this.password != "" &&
        this.isTermsConditionAccepted === true &&
        this.mobile != "" &&
        this.ldapid != "" &&
        this.email != "" &&
        this.otp != ""
      );
    },
    validateGenerateOTP() {
      return (
        this.mobile != "" &&
        !this.errors.first("mobile") &&
        this.mobile.length > 9
      );
    },
  },
  mounted() {
    // this.initUser();
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    async checkLDAP() {
      var obj = {
        username: this.ldapid,
        password: this.password,
        isLogin: false,
      };
      const { data } = await LDAPRepository.authenticate(obj);

      if (data.success) {
        this.registerUserJWt();
      } else {
        this.$vs.notify({
          title: "ERROR",
          text: "Something went wrong",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    async registerUserJWt() {
      // if (this.generatedOTP != this.otp) {
      //   console.log(this.generatedOTP);
      //   console.log(this.otp);

      //   window.alert("Incorrect OTP");
      //   this.otp = null;
      // }
      // If form is not validated or user is already login return
      // else if (!this.validateForm) {
      //   return;
      // } else {
      this.$vs.loading();
      // const reCaptcha = await this.recaptcha("adminRegister");
      const payload = {
        userData: {
          ownerName: this.displayName,
          ownerEmail: this.email,
          ownerMobile: this.mobile,
          dob: this.dob,
          gender: this.gender,
          password: this.password,
          confirmPassword: null,
          source: "website",
          status: this.isInvited ? 1 : 0,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
          communityID: null,
        },
        // token: reCaptcha
      };

      const { data } = await userRepository.signup(payload);
      ////debugger;
      if (data.data.ResponseCode == 100) {
        this.mapLDAP();
        this.$vs.loading.close();
        if (this.isInvited) {
          this.login(data);
        } else {
          this.getCommunityManagers(data);
        }
        // self.$router
        // .push("/community-home")
        // .catch(() => {});
      }
      // }
    },
    async mapLDAP() {
      const payload = {
        mobile: this.mobile,
        empId: this.ldapid,
      };

      await LDAPRepository.mapLDAP(payload);
    },
    async generateOTP() {
 
      this.$vs.loading();
      const payload = {
        mobile: this.mobile,
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await userRepository.sendOTP(payload);
      if (data) {
        this.$vs.loading.close();
        this.verified = true;
        this.$vs.notify({
          time: 2000,
          title: "OTP",
          text: "OTP Send",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success"
        });
      } else {
        this.$vs.loading.close();
      }
    },
    async checkOTP() {
      this.$vs.loading();
      const payload = {
        mobile: this.mobile,
        otp: this.otp,
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await userRepository.checkOTP(payload);
      if (data.ResponseCode == 101) {
        this.$vs.loading.close();
        this.checkLDAP();
      } else if (data.ResponseCode == 102) {
        this.$vs.loading.close();
        this.otp = "";
        this.otpPopup = false;
        window.alert("Incorrect OTP");
      } else {
        this.$vs.loading.close();
      }
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async checkMobileNumber() {
      if (!this.mobile) return;
      let self = this;
      this.$vs.loading();
      const payload = {
        phoneNumber: this.mobile,
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await userRepository.checkPhoneNumber(payload);
      if (data.length > 0) {
        self.$vs.loading.close();
        self.mobile = "";
        self.$vs.notify({
          title: "Error",
          text: "User already exists",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      } else {
        self.$vs.loading.close();
        // this.checkInvitationStatus();
      }
    },
    async checkInvitationStatus() {
      this.$vs.loading();
      const payload = {
        phoneNumber: this.mobile,
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await userRepository.checkInvitationStatus(payload);

      if (data.length > 0) {
        this.isInvited = true;
        this.$vs.loading.close();
      } else {
        this.isInvited = false;
        this.$vs.loading.close();
      }
    },
    async login(data) {
      let self = this;
      // this.$vs.loading();
      this.$acl.change("editor");

      if (data.data.ResponseCode == 100) {
        // var loginId = currentCommunityName.communityName + "_" + self.mobile;
        // loginId = loginId.replace(/ /g, "");
        // var searchParamsRecruiter = { login: loginId };
        // if (data.data.userData != null) {
        //   var recruiterProfile = {
        //     login: loginId,
        //     password: "12345678",
        //     full_name: self.displayName
        //   };
        // }
        // const usersInfo = await AuthService.getUserFromServerById(
        //   searchParamsRecruiter
        // );

        // if (usersInfo == false) {
        //   AuthService.signUp(recruiterProfile)
        //     .then(() => {
        //       // alert("success");
        //       self.loginToApp(data);
        //     })
        //     .catch(error => {
        //       // alert(error);
        //     });
        // } else {
        //   var userCredentials = { login: loginId, password: "12345678" };
        //   AuthService.signIn(userCredentials)
        //     .then(() => {
        //       // alert("success login");
        self.loginToApp(data);
        //     })
        //     .catch(error => {
        //       // alert(error);
        //     });
      }
      //   } else {
      //     this.loginToApp(data);
      //   }
    },
    async loginToApp(response) {
      // var obj = {
      //   ID: response.data.userMobile,
      //   connectycubeId: JSON.parse(
      //     localStorage.getItem("CURRENT_USER_SESSION_KEY")
      //   ).id,
      //   communityName: currentCommunityName.communityName
      // };
      // const { data } = await userRepository.UpdateCoonectyCubeId(obj);
      // console.log(data.data, "RESP");

      //debugger;
      if (response.data.ResponseCode == 100) {
        //debugger;
        // this.$vs.loading.close();
        localStorage.removeItem("token");
        localStorage.setItem("token", response.token);
        this.$store.state.token = jwt.decode(localStorage.getItem("token"));
        if (response.data.managertoken != null) {
          localStorage.setItem("managertoken", response.managertoken);
          this.$store.state.managertoken = jwt.decode(
            localStorage.getItem("managertoken")
          );
        }
        this.$session.start();
        this.$session.set("jwt", response.token);

        this.$router.push("/community-home").catch(() => {});
      } else {
        ////debugger;
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: response.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    async getCommunityManagers(userdata) {
      this.$vs.loading();
      const payload = {
        communityName: currentCommunityName.communityName,
      };
      const { data } = await userRepository.getCommunityManagers(payload);

      if (data) {
        this.$vs.loading.close();
        this.communityManagers = data.map((x) => x.usersDetails_UniqueId);
        this.insertInvitationNotification(userdata);
      }
    },
    openTerms() {
      // this.$router.push("/pages/terms").catch(() => {});
      let routeData = this.$router.resolve({ name: "page-terms" });
      window.open(routeData.href, "_blank");
    },
    async insertInvitationNotification(userdata) {
      this.$vs.loading();
      let payload = {
        notificationData: {
          initiaterId: userdata.data.uuid,
          initiaterName: userdata.data.username,
          initiaterImage: userdata.data.userImage,
          recepientId: this.communityManagers,
          recepientType: 1,
          notificationMessage: `(${userdata.data.userMobile}) wants to join the community`,
          notificationTitle: "Community Join Request",
          notificationEntityType: "Community Join",
          notificationEntityId: userdata.data.uuid,
          notificationReadStatus: 0,
        },
        communityData: {
          communityName: currentCommunityName.communityName,
        },
      };
      const { data } = await notificationRepository.addNotification(payload);
      if (data) {
        this.$vs.loading.close();
        this.$swal({
          icon: "success",
          title:
            "Your registration request has been successfully sent to the admin.",
        });
        this.emptyForm();
        // this.$vs.notify({
        //   color: "success",
        //   title: "Community Join Request Sent"
        // });
      }
    },
    emptyForm() {
      this.displayName = "";
      this.email = "";
      this.password = "";
      this.mobile = "";
      this.confirm_password = "";
      this.isTermsConditionAccepted = true;
      this.otp = "";
      this.verified = false;
      this.generatedOTP = 0;
      this.isInvited = false;
      this.isOTPGenerated = false;
    },
  },
  components: {
    Datepicker,
  },
};
</script>
<style>
.vs-con-input-label.is-label-placeholder {
  margin-top: 0px !important;
}
</style>
